.main-page-component {
  color: #424f87;
  padding-bottom: 20rem;
}
.main-page-component .main-page-header-section__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15rem 0 0 0;
  -webkit-transition: padding 0.3s linear;
  -moz-transition: padding 0.3s linear;
  -ms-transition: padding 0.3s linear;
  -o-transition: padding 0.3s linear;
  transition: padding 0.3s linear;
}
.main-page-component .main-page-header-section__container--left {
  padding-right: 5rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 70rem;
  max-width: 60%;
  padding-left: 5rem;
  text-align: justify;
}
.main-page-component .main-page-header-section__container--left span {
  padding-bottom: 2rem;
  font-weight: 400;
  display: block;
}
.main-page-component .main-page-header-section__container--right {
  display: inline-flex;
  flex-direction: column;
  padding-left: 15rem;
  max-width: 40%;
}
.main-page-component .main-page-header-section__container--right img {
  max-width: 100%;
  height: auto;
}
.main-page-component .main-page-header__title {
  font-weight: 600;
  font-size: 5rem;
  text-transform: uppercase;
  margin-bottom: 0;
  -webkit-transition: font-size 0.3s linear;
  -moz-transition: font-size 0.3s linear;
  -ms-transition: font-size 0.3s linear;
  -o-transition: font-size 0.3s linear;
  transition: font-size 0.3s linear;
}
.main-page-component .main-page-header__subtitle {
  font-weight: 600;
  font-size: 4rem;
  text-transform: uppercase;
  margin: 0;
  -webkit-transition: font-size 0.3s linear;
  -moz-transition: font-size 0.3s linear;
  -ms-transition: font-size 0.3s linear;
  -o-transition: font-size 0.3s linear;
  transition: font-size 0.3s linear;
}
.main-page-component .main-page-header__desc {
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0 0 4rem 0;
  -webkit-transition: font-size 0.3s linear;
  -moz-transition: font-size 0.3s linear;
  -ms-transition: font-size 0.3s linear;
  -o-transition: font-size 0.3s linear;
  transition: font-size 0.3s linear;
}
.main-page-component .main-page-header__introduction {
  font-size: 1.6rem;
  line-height: 3rem;
  -webkit-transition: font-size 0.3s linear;
  -moz-transition: font-size 0.3s linear;
  -ms-transition: font-size 0.3s linear;
  -o-transition: font-size 0.3s linear;
  transition: font-size 0.3s linear;
}
@media screen and (max-width: 1700px) {
  .main-page-component .main-page-header-section__container {
    align-items: center;
    justify-self: center;
    padding: 10rem 2rem 15rem 2rem;
  }
  .main-page-component .main-page-header-section__container--left {
    padding: 0;
    max-width: 50rem;
  }
}
@media screen and (max-width: 1290px) {
  .main-page-component .main-page-header-section__container--right {
    padding-left: 5rem;
  }
}
@media screen and (max-width: 1200px) {
  .main-page-component .main-page-header-section__container {
    padding: 10rem 2rem 10rem 2rem;
  }
  .main-page-component .main-page-header-section__container--left {
    min-width: 60rem;
  }
  .main-page-component .main-page-header-section__container--right {
    padding-left: 2rem;
  }
  .main-page-component .main-page-header__title {
    font-size: 4rem;
  }
  .main-page-component .main-page-header__subtitle {
    font-size: 3rem;
  }
  .main-page-component .main-page-header__desc {
    font-size: 1.6rem;
  }
  .main-page-component .main-page-header__introduction {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 1023px) {
  .main-page-component .main-page-header-section__container {
    padding: 10rem 4rem 0rem 4rem;
    flex-wrap: wrap;
  }
  .main-page-component .main-page-header-section__container--left {
    max-width: 100%;
    min-width: auto;
    padding: 0;
  }
  .main-page-component .main-page-header-section__container--right {
    margin-top: 5rem;
    max-width: 100%;
    padding-left: 0;
    align-items: center;
  }
}
@media screen and (max-width: 576px) {
  .main-page-component .main-page-header-section__container {
    padding: 5rem 2rem 0 2rem;
  }
  .main-page-component .main-page-header__title {
    font-size: 3rem;
  }
}
