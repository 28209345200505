.about-section {
  background-color: #edf6ff;
  padding: 6rem 2rem 25rem 2rem;
  color: #424f87;
}
.about-section__title {
  font-size: 3rem;
  text-align: center;
  padding-bottom: 4rem;
}
.about-section__title:last-of-type {
  padding-top: 4rem;
}
.about-section__table {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  margin: 0 auto;
  max-width: 120rem;
}
.about-section__table--row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-bottom: 0.5rem;
  padding: 1rem 2rem;
}
.about-section__table--col {
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.about-section__table .title-column {
  width: 30%;
  font-weight: 600;
}
.about-section__table .description-column {
  width: 70%;
}
@media screen and (max-width: 768px) {
  .about-section__table--row {
    flex-wrap: wrap;
    padding: 2rem 2rem;
  }
  .about-section__table .title-column {
    width: 100%;
    padding-bottom: 0.5rem;
  }
  .about-section__table .description-column {
    width: 100%;
  }
  .about-section__table .description-column .list-desc {
    padding: 0.2rem 0;
  }
  .about-section__table .description-column .list-desc::before {
    content: "- ";
  }
}
