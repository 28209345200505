.foundations-wrapper {
  padding: 10rem 2rem 24rem 2rem;
}
.foundations-wrapper .foundations {
  margin: 0 auto;
  max-width: 135rem;
  padding: 4rem 7rem;
  background-color: white;
  -webkit-box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.2);
  color: #424f87;
}
.foundations-wrapper .foundations__title {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.foundations-wrapper .foundations__sub-title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1.5rem;
}
.foundations-wrapper .foundations__block {
  font-size: 1.4rem;
  margin-bottom: 3rem;
  line-height: 2.5rem;
  text-align: justify;
}
.foundations-wrapper .foundations__card-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-top: 1.5rem;
  flex-wrap: wrap;
  width: 100%;
}
.foundations-wrapper .foundations__card-container .card {
  flex: 0 0 40%;
  padding: 0 1rem 2rem 1rem;
}
.foundations-wrapper .foundations__card-container .foundation-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
  height: 100%;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  max-width: 350px;
  margin: 0 auto;
}
.foundations-wrapper .foundations__card-container .foundation-card__title {
  background-color: #424f87;
  font-size: 1.4rem;
  min-height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  color: white;
  -webkit-border-top-right-radius: 1.5rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 1.5rem;
  -moz-border-radius-topright: 1.5rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 1.5rem;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 1.5rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  padding: 1rem 1.5rem;
  width: 100%;
}
.foundations-wrapper .foundations__card-container .foundation-card__description {
  background-color: #edf6ff;
  min-height: 10rem;
  padding: 2rem 2rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  height: 100%;
}
.foundations-wrapper .foundations__card-container .foundation-card__investment {
  background-color: #424f87;
  color: white;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 1.5rem;
  -webkit-border-bottom-left-radius: 1.5rem;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 1.5rem;
  -moz-border-radius-bottomleft: 1.5rem;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1.2rem;
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-align: left;
  position: relative;
  width: 100%;
}
.foundations-wrapper .foundations__card-container .foundation-card__investment .arrow-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 1rem;
  width: 2.5rem;
  height: 2rem;
}
.foundations-wrapper .foundations__card-container .foundation-card__investment .arrow-container .arrow {
  border-width: 0.3rem 0.3rem 0 0;
  border-color: white white transparent transparent;
  display: block;
  border-style: solid;
  padding: 0.4rem;
  position: absolute;
  right: 0.5rem;
  top: 0.4rem;
  -webkit-transition: border-color 0.3s linear;
  -moz-transition: border-color 0.3s linear;
  -ms-transition: border-color 0.3s linear;
  -o-transition: border-color 0.3s linear;
  transition: border-color 0.3s linear;
}
.foundations-wrapper .foundations__card-container .foundation-card__investment .arrow-container .arrow-body {
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
  width: 1.4rem;
  height: 0.3rem;
  background-color: white;
  display: block;
}
.foundations-wrapper .foundations__card-container .foundation-card__investment .arrow-container:hover .arrow {
  border-color: #2a325e #2a325e transparent;
}
.foundations-wrapper .foundations__card-container .foundation-card__investment .arrow-container:hover .arrow-body {
  background-color: #2a325e;
}
.foundations-wrapper .foundations__card-container .foundation-card__footer-text {
  font-size: 1.6rem;
  font-weight: 600;
}
@media screen and (max-width: 976px) {
  .foundations-wrapper .foundations__card-container .card {
    flex: 0 0 50%;
  }
}
@media screen and (max-width: 768px) {
  .foundations-wrapper .foundations {
    padding: 4rem 2rem;
  }
}
@media screen and (max-width: 500px) {
  .foundations-wrapper .foundations {
    padding: 4rem 2rem;
  }
  .foundations-wrapper .foundations__card-container .card {
    flex: 0 0 100%;
  }
}
@media screen and (max-width: 576px) {
  .foundations-wrapper {
    padding: 5rem 0 22rem 0;
  }
}
