.connections-wrapper {
  padding: 10rem 2rem 24rem 2rem;
}
.connections-wrapper .connection {
  background-color: #edf6ff;
  max-width: 100rem;
  margin: 0 auto;
  color: #424f87;
  padding: 4rem 7rem;
}
.connections-wrapper .connection__title {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
}
.connections-wrapper .connection__table {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 2rem 2rem;
  font-size: 1.4rem;
}
.connections-wrapper .connection__table--row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem 2rem;
}
.connections-wrapper .connection__table--col {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
}
.connections-wrapper .connection__table .title-col {
  width: 40%;
  font-weight: 600;
}
.connections-wrapper .connection__table .desc-col {
  width: 60%;
}
@media screen and (max-width: 768px) {
  .connections-wrapper .connection {
    padding: 4rem 2rem;
  }
  .connections-wrapper .connection__title {
    font-size: 2.5rem;
  }
  .connections-wrapper .connection__table--row {
    padding-left: 0;
    padding-right: 0;
  }
  .connections-wrapper .connection__table--row .title-col {
    width: 100%;
    text-align: center;
  }
  .connections-wrapper .connection__table--row .desc-col {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 576px) {
  .connections-wrapper {
    padding: 6rem 0 20rem 0;
  }
}
