.complaints-wrapper {
  padding: 10rem 2rem 24rem 2rem;
}
.complaints-wrapper .complaints {
  margin: 0 auto;
  max-width: 120rem;
  color: #424f87;
  padding: 4rem 7rem;
  background-color: white;
  -webkit-box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.2);
}
.complaints-wrapper .complaints__title {
  font-size: 3rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 3rem;
}
.complaints-wrapper .complaints__title--small {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}
.complaints-wrapper .complaints__strong {
  font-size: 1.6rem;
  margin-bottom: 0rem;
  font-weight: 600;
  padding: 0.5rem 0;
}
.complaints-wrapper .complaints ol {
  margin-top: 0;
  list-style-type: none;
  padding-left: 2rem;
  font-size: 1.4rem;
}
.complaints-wrapper .complaints ol li {
  line-height: 2.4rem;
}
.complaints-wrapper .complaints ul {
  margin-top: 0;
  margin-bottom: 2.5rem;
  font-size: 1.4rem;
  text-align: justify;
}
.complaints-wrapper .complaints ul li {
  line-height: 2.4rem;
}
.complaints-wrapper .complaints__text {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  text-align: justify;
}
.complaints-wrapper .complaints__description {
  text-align: left;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 2rem;
}
.complaints-wrapper .complaints__files {
  display: flex;
  flex-direction: column;
}
.complaints-wrapper .complaints__files--container {
  background-color: #edf6ff;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  align-items: center;
  font-size: 1.4rem;
  display: flex;
  width: 100%;
}
.complaints-wrapper .complaints__files--container span {
  padding-right: 2rem;
}
@media screen and (max-width: 576px) {
  .complaints-wrapper .complaints {
    padding: 4rem;
  }
}
@media screen and (max-width: 500px) {
  .complaints-wrapper .complaints {
    padding: 4rem 2rem;
  }
  .complaints-wrapper .complaints__files--container {
    flex-wrap: wrap;
  }
  .complaints-wrapper .complaints__files--container span {
    display: block;
    width: 100%;
  }
  .complaints-wrapper .complaints__files--container .a-button {
    display: block;
    margin-left: auto;
    margin-right: 0;
    margin-top: 1rem;
  }
}
