.foundation-page {
  padding: 4rem;
  margin: 0 auto;
  max-width: 120rem;
  color: #424f87;
  -webkit-box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.1);
  background-color: #f7fbff;
}
.foundation-page__title {
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
}
.foundation-page__title .text {
  padding-right: 1rem;
}
.foundation-page__title .back-button {
  border: none;
  background-color: #424f87;
  color: white;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
}
.foundation-page__title .back-button:hover {
  background-color: #2a325e;
}
.foundation-page__text-block {
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding-bottom: 2rem;
  text-align: justify;
}
.foundation-page__link-block {
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding-bottom: 1rem;
  text-align: justify;
}
.foundation-page__table {
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.foundation-page__table--row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #edf6ff;
  padding: 2rem 3rem;
  margin-bottom: 1rem;
  width: 100%;
}
.foundation-page__table--col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  padding: 0.5rem 1rem;
}
.foundation-page__table--col .list-desc {
  padding: 0.5rem 0;
}
.foundation-page__table--col .list-desc::before {
  content: "- ";
}
.foundation-page__table .title-col {
  font-size: 1.6rem;
  font-weight: 600;
}
.foundation-page__table .desc-col {
  font-size: 1.4rem;
}
@media screen and (max-width: 768px) {
  .foundation-page {
    padding: 4rem 2rem;
  }
  .foundation-page__table--row {
    flex-direction: column;
    padding: 2rem;
  }
  .foundation-page__table--col {
    width: 100%;
    padding: 0;
  }
  .foundation-page__table--col .list-desc {
    padding: 0.3rem 0;
  }
  .foundation-page__table .desc-col {
    padding-top: 0.5rem;
  }
}
